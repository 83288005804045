import styled from "styled-components";

export const FooterStyle = styled.footer`
display:flex;
justify-content: center;
   p{
    text-align: center;
    font-size:clamp(.8rem,2vw,.9rem);
    color:#E0E0E0;
    padding-bottom:1rem;
  
 }
   
`